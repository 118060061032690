@use '@angular/material' as mat;

@include mat.core();

// Define a dark theme
$dark-theme: mat.define-dark-theme((
 color: (
   primary: mat.define-palette(mat.$indigo-palette),
   accent: mat.define-palette(mat.$green-palette, A200, A100, A400),
 ),
  // Only include `typography` and `density` in the default dark theme.
  typography: mat.define-typography-config(),
  density: 0,
));

// Define a light theme
$light-theme: mat.define-light-theme((
 color: (
   primary: mat.define-palette(mat.$blue-grey-palette),
   accent: mat.define-palette(mat.$blue-grey-palette, A200, A100, A400),
 ),
));

// Apply the dark theme by default
@include mat.all-component-themes($dark-theme);

// Apply the light theme only when the user prefers light themes.
@media (prefers-color-scheme: light) {
  @include mat.all-component-themes($light-theme);
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.blogposts {
    margin: auto;
    // width: 50%;
}

html, body, .app-content {
    // overflow: hidden;
    margin: 0;
    height: 100%;
    box-sizing: border-box;
    // color: #e0e0e0;
}


